
import { defineComponent } from 'vue'
import DataService from '@/services/DataService'
import iMovies from '@/types/Movies'
import ResponseData from '@/types/ResponseData'

export default defineComponent({
  name: 'Home',
  components: {},
  data() {
    return {
      movies: [] as iMovies[],
      filteredMovie: [] as iMovies[],
      title: '',
      search: '',
      ascending: false,
      sortColumn: '',
    }
  },
  methods: {
    getAllMovies() {
      DataService.getAll()
        .then((response: ResponseData) => {
          this.movies = response.data as iMovies[]
          this.filteredMovie = this.movies
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    deleteMovie(id: number) {
      DataService.delete(id)
        .then((response: ResponseData) => {
          console.log(response.data)
          const index = this.movies.findIndex((movie) => movie.id === id)
          this.movies.splice(index, 1)
          this.filteredMovie = this.movies
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    sortTable(col: string) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }

      var ascending = this.ascending

      this.filteredMovie.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      })
    },
  },
  created() {
    this.getAllMovies()
  },
  watch: {
    // Traque les changements de la propriété search et filtre les films
    search(value) {
      let resultMovies = this.movies

      let searchString = value.trim().toLowerCase()
      resultMovies = resultMovies.filter(function (item: any) {
        if (item.title.toLowerCase().indexOf(searchString) !== -1) {
          return item
        }
      })
      console.log(resultMovies)
      this.filteredMovie = resultMovies
    },
  },
  computed: {
    columns() {
      if (this.filteredMovie.length == 0) {
        return []
      }
      return Object.keys(this.filteredMovie)
    },
  },
})
